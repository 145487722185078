export const diacriticSpaceSensitiveRegex = (string = '') => {
  return new RegExp(
    string
      .toLowerCase()
      .replace(/[aáàã]/g, '[aáàã]')
      .replace(/[eé]/g, '[eé]')
      .replace(/[ií]/g, '[ií]')
      .replace(/[oó]/g, '[oó]')
      .replace(/[uú]/g, '[uú]')
      .replace(/[cç]/g, '[cç]')
      .replace(/[\s\-_]/g, '[\\s\\-\\_]')
      .replace(/%/g, '.*'),
    'ig'
  )
}
