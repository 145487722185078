var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","single-line":"","placeholder":"Busca","append-icon":"search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('mf-status-filter',{attrs:{"items":_vm.unitStatus},on:{"input":_vm.getFilterResult}}),_c('export-to-excel',{attrs:{"disabled":_vm.$apollo.loading,"btn-class":"mx-2","filename":"lojas","fields":_vm.fields,"fetch-fn":_vm.loadExportItems}}),_c('mf-button',{attrs:{"disabled":_vm.$apollo.loading,"color":"primary","label":"Adicionar loja"},on:{"click":_vm.triggerAddUnit}})],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-n6",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('headers-data-table',{attrs:{"headers":_vm.headers,"default-headers":_vm.getDefaultHeadersOptions(),"headers-settings-name":"headers-list-units-retailers"},on:{"update:headers":function($event){_vm.headers=$event}}})],1)],1),_c('v-data-table',{staticClass:"clickable-table",attrs:{"fixed-header":"","loading":_vm.$apollo.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"options":_vm.options,"headers":_vm.computedHeaders,"items":_vm.unitsDataItems},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openMenu},nativeOn:{"click":function($event){return _vm.testClickNative($event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.getUnitStatusLabel(item.status).color}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.getUnitStatusLabel(item.status).label)+" ")]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cnpj")(item.cnpj))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.account_units",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.account_units.length; }) || '-')+" ")]}},{key:"item.is_test",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_test ? 'Teste' : 'Produção')+" ")]}},{key:"item.cbm",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.cbm ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")])]}},{key:"item.mercashop",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.mercashop ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")])]}},{key:"item.mercapromo",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.mercapromo ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")])]}},{key:"item.promotrade",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.promotrade ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.updated_at))+" ")]}}],null,true)}),_c('v-menu',{attrs:{"position-x":_vm.menuX,"position-y":_vm.menuY,"absolute":"","offset-y":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.toggleEditUnit(_vm.selectedItem)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.toggleRemoveUnit(_vm.selectedItem)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Remove")])],1)],1)],1),_c('remove-unit',{attrs:{"unit":_vm.selectedUnit,"saveCallback":_vm.removeUnit},model:{value:(_vm.removingUnit),callback:function ($$v) {_vm.removingUnit=$$v},expression:"removingUnit"}}),_c('edit-unit',{attrs:{"value":_vm.editingUnit,"unit":_vm.selectedUnit,"saveCallback":_vm.editUnit},on:{"close":function($event){_vm.editingUnit = false}}}),_c('add-unit',{attrs:{"save-fn":_vm.addUnit},model:{value:(_vm.addingUnit),callback:function ($$v) {_vm.addingUnit=$$v},expression:"addingUnit"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }