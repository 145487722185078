<template>
  <v-card class="mt-3" flat>
    <v-card-title>
      <v-text-field v-model="search" class="pt-0 mt-0" hide-details single-line="" placeholder="Busca" append-icon="search" outlined />
      <v-spacer />
      <mf-status-filter :items="unitStatus" @input="getFilterResult" />
      <export-to-excel :disabled="$apollo.loading" btn-class="mx-2" filename="lojas" :fields="fields" :fetch-fn="loadExportItems" />
      <mf-button :disabled="$apollo.loading" color="primary" label="Adicionar loja" @click="triggerAddUnit"></mf-button>
    </v-card-title>
    <v-card-text>
      <v-row justify="end" class="mt-n6">
        <v-col cols="auto">
          <headers-data-table :headers.sync="headers" :default-headers="getDefaultHeadersOptions()" headers-settings-name="headers-list-units-retailers" />
        </v-col>
      </v-row>
      <v-data-table
        fixed-header
        class="clickable-table"
        :loading="$apollo.loading"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
        :options.sync="options"
        :headers="computedHeaders"
        :items="unitsDataItems"
        @click.native="testClickNative"
        @click:row="openMenu"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-icon class="mr-2" x-small :color="getUnitStatusLabel(item.status).color">mdi-brightness-1</v-icon>{{ getUnitStatusLabel(item.status).label }}
        </template>
        <template v-slot:[`item.cnpj`]="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.account_units`]="{ item }">
          {{ getSafe(() => item.account_units.length) || '-' }}
        </template>
        <template v-slot:[`item.is_test`]="{ item }">
          {{ item.is_test ? 'Teste' : 'Produção' }}
        </template>
        <template v-slot:[`item.cbm`]="{ item }">
          <v-icon class="mr-2" x-small :color="item.cbm ? 'success' : 'error'">mdi-brightness-1</v-icon>
        </template>
        <template v-slot:[`item.mercashop`]="{ item }">
          <v-icon class="mr-2" x-small :color="item.mercashop ? 'success' : 'error'">mdi-brightness-1</v-icon>
        </template>
        <template v-slot:[`item.mercapromo`]="{ item }">
          <v-icon class="mr-2" x-small :color="item.mercapromo ? 'success' : 'error'">mdi-brightness-1</v-icon>
        </template>
        <template v-slot:[`item.promotrade`]="{ item }">
          <v-icon class="mr-2" x-small :color="item.promotrade ? 'success' : 'error'">mdi-brightness-1</v-icon>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | date }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ item.updated_at | date }}
        </template>
      </v-data-table>
      <v-menu v-model="menu" :position-x="menuX" :position-y="menuY" absolute offset-y>
        <v-list dense>
          <v-list-item dense @click="toggleEditUnit(selectedItem)">
            <v-list-item-icon class="mr-1">
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="toggleRemoveUnit(selectedItem)">
            <v-list-item-icon class="mr-1">
              <v-icon small>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Remove</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <remove-unit v-model="removingUnit" :unit="selectedUnit" :saveCallback="removeUnit" />
      <edit-unit :value="editingUnit" :unit="selectedUnit" :saveCallback="editUnit" @close="editingUnit = false" />
      <add-unit v-model="addingUnit" :save-fn="addUnit" />
    </v-card-text>
  </v-card>
</template>

<script>
import {
  QUERY_GET_CLIENT_UNITS_BY_CLIENT,
  MUTATION_ADD_CLIENT_UNIT,
  MUTATION_UPDATE_CLIENT_UNIT,
  MUTATION_DELETE_CLIENT_UNIT
} from '@/modules/retailers/graphql'
import { deepDelete } from '@/helpers/deepDelete'
import { headers } from '@/mixin'
import { cloneDeep } from 'lodash'
import { diacriticSpaceSensitiveRegex } from '@/helpers/search.js'

export default {
  components: {
    ExportToExcel: () => import('@/components/imports/ExportToExcel.vue'),
    HeadersDataTable: () => import('@/components/atomic-components/molecules/HeadersDataTable'),
    EditUnit: () => import('@/modules/retailers/views/components/units/EditUnit.vue'),
    AddUnit: () => import('@/modules/retailers/views/components/units/AddUnit.vue'),
    RemoveUnit: () => import('@/modules/retailers/views/components/units/RemoveUnit.vue')
  },
  mixins: [headers],
  props: {
    clientId: {
      type: String,
      default: () => ''
    }
  },

  data: () => ({
    search: '',
    statusToSearch: [],
    options: {},
    unitsData: [],
    exporting: false,
    headers: [],
    editingUnit: false,
    removingUnit: false,
    selectedUnit: {},
    addingUnit: false,
    menu: false,
    menuX: 0,
    menuY: 0,
    selectedItem: null
  }),
  computed: {
    unitStatus() {
      return [
        { label: 'Cadastrada na account', value: 'REGISTERED', color: '#07DD18', active: true },
        { label: 'Configurada', value: 'CONFIGURED', color: '#EDB900', active: true },
        { label: 'Pendente de configuração', value: 'PENDING_CONFIGURATION', color: '#FF5252', active: true }
      ]
    },
    unitsDataItems() {
      let filteredUnits = this.unitsData
      if (this.statusToSearch.length) {
        filteredUnits = filteredUnits.filter(item => this.statusToSearch.includes(item.status))
      }
      if (this.search) {
        filteredUnits = filteredUnits.filter(item => {
          const digitSearch = this.search.replace(/\D/g, '')
          const digitSearchRegex = new RegExp(digitSearch)
          const searchRegex = diacriticSpaceSensitiveRegex(this.search)

          return (digitSearch && digitSearchRegex.test(item.cnpj.replace(/\D/g, ''))) || searchRegex.test(item.name)
        })
      }
      return filteredUnits
    },
    tableHeaders() {
      return [
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          width: '10%',
          isActive: true
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          width: '5%',
          isActive: true
        },
        {
          text: 'Nome',
          value: 'name',
          width: '15%',
          isActive: true
        },
        {
          text: 'Accounts',
          value: 'account_units',
          width: '10%',
          isActive: true
        },
        {
          text: 'Tipo loja',
          value: 'is_test',
          width: '6%',
          isActive: true
        },
        {
          text: 'CBM',
          value: 'cbm',
          width: '4%',
          isActive: true
        },
        {
          text: 'Mercashop',
          value: 'mercashop',
          width: '4%',
          isActive: true
        },
        {
          text: 'Mercapromo',
          value: 'mercapromo',
          width: '4%',
          isActive: true
        },
        {
          text: 'Promotrade',
          value: 'promotrade',
          width: '4%',
          isActive: true
        },
        {
          text: 'Criada em',
          value: 'created_at',
          width: '8%',
          isActive: false
        },
        {
          text: 'Atualizada em',
          value: 'updated_at',
          width: '8%',
          isActive: true
        }
      ]
    },
    fields() {
      return {
        ID_Loja: '_id',
        CNPJ: 'cnpj',
        Status: 'status',
        Nome: 'name',
        CBM: 'cbm',
        Loja_teste: 'is_test',
        Mercashop: 'mercashop',
        Mercapromo: 'mercapromo',
        Criado_em: 'created_at',
        Atualizado_em: 'updated_at'
      }
    }
  },
  apollo: {
    units: {
      query: QUERY_GET_CLIENT_UNITS_BY_CLIENT,
      fetchPolicy: 'network-only',
      variables() {
        return {
          client_units: { client_id: this.$route.params.id }
        }
      },
      update({ clientUnits }) {
        this.unitsData = clientUnits
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  watch: {
    menu(val) {
      if (!val) {
        this.selectedItem = null
      }
    }
  },
  mounted() {
    if (this.headers.length < 1) this.headers = cloneDeep(this.tableHeaders)
  },
  methods: {
    getFilterResult(statusToSearch) {
      this.statusToSearch = statusToSearch
    },
    testClickNative(event) {
      if (this.selectedItem) {
        this.menuX = event.clientX
        this.menuY = event.clientY
        this.menu = true
      }
    },
    openMenu(item) {
      this.selectedItem = item
    },

    getUnitStatusLabel(status) {
      return this.unitStatus.find(({ value }) => value === status) || {}
    },
    triggerAddUnit() {
      this.addingUnit = true
    },
    toggleEditUnit(item) {
      this.selectedUnit = item
      this.editingUnit = true
    },
    toggleRemoveUnit(item) {
      this.selectedUnit = item
      this.removingUnit = true
    },
    async addUnit(unit) {
      // apollo mutate
      deepDelete(unit, '__typename')
      unit.client_id = this.$route.params.id
      await this.$apollo.mutate({
        mutation: MUTATION_ADD_CLIENT_UNIT,
        variables: {
          insert_client_unit: unit
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.$apollo.queries.units.refetch()
    },
    async editUnit(unit, unitId) {
      // apollo mutate
      deepDelete(unit, '__typename')
      unit._id = unitId
      await this.$apollo.mutate({
        mutation: MUTATION_UPDATE_CLIENT_UNIT,
        variables: {
          update_client_unit: unit
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.$apollo.queries.units.refetch()
    },
    async removeUnit(unitId) {
      // apollo mutate
      await this.$apollo.mutate({
        mutation: MUTATION_DELETE_CLIENT_UNIT,
        variables: {
          client_unit_id: unitId
        },
        context: {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      })
      this.$apollo.queries.units.refetch()
    },
    getDefaultHeadersOptions() {
      return cloneDeep(this.tableHeaders)
    },
    async loadExportItems() {
      return this.unitsData
    }
  }
}
</script>

<style lang="css">
.clickable-table td {
  cursor: pointer;
}
</style>
